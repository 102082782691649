import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Polygon from '../../../assets/images/Polygon.png';
import '../../review.scss'
import FilterReview from "../../../components/Filters/FilterReview";
import HeaderItemSelectRSNT from "../../../components/Base/HeaderitemSelectRSNT/HeaderItemSelectRSNT";
import MainNavbar from "../../../components/Layout/MainNavbar/MainNavbar";

ChartJS.register(ArcElement, Tooltip, Legend);


const conversionChart = () => {

  const data = {
    labels: [
      '3rd party',
      'Organic'
    ],
    datasets: [{
      label: 'Reviews',
      data: [28165, 24674],
      backgroundColor: [
        'rgb(233, 233, 233)',
        'rgb(34, 40, 47)'
      ],
      hoverOffset: 4
    }]
  };


  return (
    <div>
        <MainNavbar   head={"Review and Ratings over time"} text={<HeaderItemSelectRSNT  activeItem={"traffic"} />} />
        <div className="reviews-and-rating-overtime">
            <FilterReview />
            <div className="conversation-container">
                      <div className="conversation-header"> 
                        <p className="conversation-text">Graph</p>
                        <p className="conversation-text">Table</p>
                      </div>

                      <div className="conversation-wrap">
                        <p className="conversation-title">Usage Report</p>
                      </div>

                      <div className="conversation-chart"> 
                          <div className="conversation-chart-email">
                            <p className="conversation-chart-text">Email Conversion</p>
                            <img src={Polygon} alt="graph" />
                          </div>

                          <div className="conversation-chart-email">
                            <p className="conversation-chart-text">Sms Conversion</p>
                            <img src={Polygon} alt="graph" />
                          </div>

                          <div className="conversation-chart-email">
                            <p className="conversation-chart-text">Reviews</p>
                            <Pie data={data} />
                          </div>
                      </div>
                    </div>

        </div>
    </div>
  )
}

export default conversionChart;