import {Progress} from "antd";

interface CircularRatingI {
  label: string;
  rating: number;
  strokeWidth?: number;
  color?: string;
  trailColor?: string;
  width?: number;
  innerLabelClassName?: string;
  labelColor?: string;
  outerLabelClassName?: string;
  outlineColor?: string;
}

const ProgressBar = ({
  label,
  rating,
  strokeWidth,
  color,
  width,
  innerLabelClassName,
  labelColor,
  outerLabelClassName,
  trailColor,
}: CircularRatingI) => {
  return (
    <div className="circularRatingComponent" style={{ display: "inline-block", textAlign: "center" }} >
      <div>
          <Progress
              type="circle"
              percent={parseFloat((rating/5*100).toFixed(2))}
              format={(percent)=>percent ? (percent/20).toFixed(1): 0}
              width={width ? width : 85 }
              strokeColor={color ? color : "#22282F"}
              strokeWidth={strokeWidth ? strokeWidth : 5 }
              trailColor={trailColor ? trailColor : "#E9E9E9"}
              style={{}}
          />
        <div
          className={(outerLabelClassName ? outerLabelClassName : "")}
          style={{ color: labelColor ? labelColor : "#000000", marginTop: 16  }}
        > {label}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
