import {Progress} from "antd";

interface LinearRatingI {
    label: string;
    score: number;
    total: number;
    strokeWidth?: number;
    color?: string;
    trailColor?: string;
    width?: number;
    innerLabelClassName?: string;
    labelColor?: string;
    outerLabelClassName?: string;
    outlineColor?: string;
    flatEdges?: boolean;
}

const LinearProgress = ({
        label,
        score,
        total,
        strokeWidth,
        color,
        width,
    flatEdges,
        innerLabelClassName,
        labelColor,
        outerLabelClassName,
        trailColor,
    }: LinearRatingI) => {
    return (
        <div className="" style={{ display: "inline-block", textAlign: "center" }} >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '270px' }}>
                <Progress
                    type="line"
                    percent={parseFloat((score/total*100).toFixed(2))}
                    format={(percent)=>percent}
                    width={width ? width : 5085 }
                    strokeColor={color ? color : "#22282F"}
                    strokeWidth={strokeWidth ? strokeWidth : 5 }
                    trailColor={trailColor ? trailColor : "#E9E9E9"}
                    strokeLinecap={flatEdges ? "butt" : "round"}
                    showInfo={false}
                    style={{}}
                />
                <div style={{ color: labelColor ? labelColor : "#000000", marginLeft: 20, whiteSpace: "pre" }}>{label}</div>
            </div>
        </div>
    );
};

export default LinearProgress;
