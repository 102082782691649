import HeaderItemSelect from "../../components/Base/HeaderitemSelect/HeaderItemSelect";
import React from "react";
import FilterReviewFull from "../../components/Filters/FilterReviewFull";
import {generateRandomInt} from "../../utils";

import './competitivePerformanceByCategory.scss';
import LinearProgressBar from "../../components/Base/LinearProgressBar";
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";

const categoryData = [
    {
        name: "beauty",
        rankings: [
            {
                merchantName: "Amazon",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "CINTR",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Walmart",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Sephora",
                score: generateRandomInt(2600, 30000),
            },
        ]
    },
    {
        name: "technology",
        rankings: [
            {
                merchantName: "Amazon",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "CINTR",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Walmart",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Sephora",
                score: generateRandomInt(2600, 30000),
            },
        ]
    },
    {
        name: "media",
        rankings: [
            {
                merchantName: "Amazon",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "CINTR",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Walmart",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Sephora",
                score: generateRandomInt(2600, 30000),
            },
        ]
    },

    {
        name: "health",
        rankings: [
            {
                merchantName: "Amazon",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "CINTR",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Walmart",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Sephora",
                score: generateRandomInt(2600, 30000),
            },
        ]
    },
    {
        name: "music",
        rankings: [
            {
                merchantName: "Amazon",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "CINTR",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Walmart",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Sephora",
                score: generateRandomInt(2600, 30000),
            },
        ]
    },
    {
        name: "dining",
        rankings: [
            {
                merchantName: "Amazon",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "CINTR",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Walmart",
                score: generateRandomInt(2600, 30000),
            },
            {
                merchantName: "Sephora",
                score: generateRandomInt(2600, 30000),
            },
        ]
    }
]
const rankingPosition = ["A", "B", "C", "D"];

const CompetitivePerformanceByCategory = () => {

    const updatedCategoryData = categoryData.map((category, index)=>{
        const newCategoryItem = {...category}
        newCategoryItem.rankings.sort((a, b) =>
            b.score - a.score)
        return newCategoryItem;
    })

    return (
        <div>
        <MainNavbar   head={"Ratings over time "} text={<HeaderItemSelect activeItem="category" />} />
            <div className="competitive-performance-by-category">
                <FilterReviewFull />

              <div className="categories">
                  { updatedCategoryData && updatedCategoryData.length > 0 ?
                      categoryData.map((data, index)=>(
                          <div className="category" key={index}>
                              <div className="category__head">
                                  <div className="category__head--main">
                                      {data.name.replace(/^./, data.name[0].toUpperCase())}
                                  </div>
                                  <div className="category__head--link">
                                      Impact for business
                                  </div>
                              </div>
                              <div className="category__hr">

                              </div>
                              <div className="category__body">
                                  {data.rankings.map((ranking, index)=>(
                                      <div className="category__item" key={index}>
                                          <div className="category__item--name">{ranking.merchantName}</div>
                                          <div className="category__item--rating">
                                              <LinearProgressBar
                                                  label={`${rankingPosition[index]} ${ranking.score}`}
                                                  score={ranking.score}
                                                  total={data.rankings[0].score}
                                                  trailColor={"#E9E9E900"}
                                                  color={"#3D9C73"}
                                                  strokeWidth={20}
                                                  flatEdges={true}

                                              />

                                          </div>
                                      </div>
                                  ))}

                              </div>
                          </div>
                      ))
                      : <></>
                  }

              </div>
            </div>
        </div>
    )
}

export default CompetitivePerformanceByCategory;