import { Menu } from 'antd';
import {NavLink, useLocation} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHouse,
    faCircleExclamation,
    faStar,
    faComments,
    faChartSimple,

} from "@fortawesome/free-solid-svg-icons";
import {
    faLightbulb,
    faChartLine
} from '@fortawesome/free-solid-svg-icons'
import React from "react";
import './MenuItems.scss'

interface  MenuItemsI {
    toggleCollapsed: ()=>void;
    topMenu: boolean;
    events: {};
}

const MenuItems = ({ toggleCollapsed }: MenuItemsI) => {
    const location = useLocation();
    const currentURL = location.pathname;

    return (
        <Menu
            items={[
                {
                    key: 'dashboard',
                    label: (
                        <NavLink onClick={toggleCollapsed} to="/" className={"menu__item " + (currentURL === "/" ? "current" : "" ) }>
                           <FontAwesomeIcon icon={faHouse} />
                            <span className="menu__item--text">Dashboard</span>
                        </NavLink>
                    ),
                },
                {
                    key: 'presence',
                    label: (
                        <NavLink onClick={toggleCollapsed} to="/presence" className={"menu__item " + (currentURL.startsWith("/presence") ? "current" : "" ) }>
                            <FontAwesomeIcon icon={faCircleExclamation} />
                            <span className="menu__item--text">Presence</span>
                        </NavLink>
                    ),
                },

                {
                    key: 'review',
                    label: (
                        <NavLink onClick={toggleCollapsed} to="/reviews" className={"menu__item " + (currentURL.startsWith("/reviews") ? "current" : "" ) }>
                            <FontAwesomeIcon icon={faStar} />
                            <span className="menu__item--text">Reviews</span>
                        </NavLink>
                    ),
                },
                {
                    key: 'social',
                    label: (
                        <NavLink onClick={toggleCollapsed} to="/social" className={"menu__item " + (currentURL.startsWith("/social") ? "current" : "" ) }>
                            <FontAwesomeIcon icon={faComments} />
                            <span className="menu__item--text">Social</span>
                        </NavLink>
                    ),
                },
                {
                    key: 'reports',
                    label: (
                        <NavLink onClick={toggleCollapsed} to="/reports" className={"menu__item " + (currentURL.startsWith("/reports") ? "current" : "" ) }>
                            <FontAwesomeIcon icon={faChartLine} />
                            <span className="menu__item--text">Reports</span>
                        </NavLink>
                    ),
                },
                {
                    key: 'insights',
                    label: (
                        <NavLink onClick={toggleCollapsed} to="/insights" className={"menu__item " + (currentURL.startsWith("/insights") ? "current" : "" ) }>
                            <FontAwesomeIcon icon={faLightbulb} />
                            <span className="menu__item--text">Insights</span>
                        </NavLink>
                    ),
                },
                {
                    key: 'competitors',
                    label: (
                        <NavLink onClick={toggleCollapsed} to="/competitors" className={"menu__item " + (currentURL.startsWith("/competitors") ? "current" : "" ) }>
                            <FontAwesomeIcon icon={faChartSimple} />
                            <span className="menu__item--text">Competitors</span>
                        </NavLink>
                    ),
                },
            ]}
        />
    )
}
export default MenuItems