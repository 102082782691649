import { DownOutlined } from '@ant-design/icons';
import { CloseCircleOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Menu, message, Space } from 'antd';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import SearchIcon from '../../assets/images/search-icon.png';
import Rating from '../../assets/images/ratingStar.png';
import Frame1 from '../../assets/images/frame1.png';
import Frame2 from '../../assets/images/frame2.png';
import ViewMore from '../../assets/images/ViewMore.png';
import reply from '../../assets/images/reply.png';
import ticket from '../../assets/images/ticket.png';
import '../review.scss'
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";
import React from "react";


const Review = () => {
    const onClick: MenuProps['onClick'] = ({ key }) => {
        message.info(`Click on item ${key}`);
      };


      const onChange = (e: CheckboxChangeEvent) => {
        console.log(`checked = ${e.target.checked}`);
      };


      const menu = (
        <Menu
          onClick={onClick}
          items={[
            {
              label: 'All selected',
              key: '1',
            },
            {
              label: '2nd menu item',
              key: '2',
            },
            {
              label: '3rd menu item',
              key: '3',
            },
          ]}
        />
      );

    return (
        <div>
            <MainNavbar head={"Overview"} text={"Detailed information about your website"} />
            <div className="review-container">
                        <div className="review-filter_container">
                        <div className="review-filter">
                                <p style={{fontWeight: 'bold'}} className="review-item">Basic</p>
                                <p className="review-item">Advanced</p>
                                <p className="review-item">Saved(15)</p>
                        </div>
                        <div className="review-item-cl"> 
                                <p className="review-item-clear">
                                    Clear 
                                </p>
                                <CloseCircleOutlined className="review-item-clear-icon"/>
                        </div>
                        </div>

                        <div className="review-filter_title">
                                <p className="filter-item">State</p>
                                <p className="filter-item">Review Source</p>
                                <p className="filter-item">Time</p>
                                <p className="filter-item">Location</p>
                        </div>

                        <div className="review-dropdown-container">
                            <div className="review-dropdown"> 
                                <Dropdown overlay={menu}>
                                <span onClick={e => e.preventDefault()}>
                                <Space>
                                    All selected
                                    <DownOutlined className="review-dropdown-icon"/>
                                </Space>
                                </span>
                                </Dropdown>
                            </div>

                            <div className="review-dropdown"> 
                                <Dropdown overlay={menu}>
                                <span onClick={e => e.preventDefault()}>
                                <Space>
                                    All selected
                                    <DownOutlined className="review-dropdown-icon"/>
                                </Space>
                                </span>
                                </Dropdown>
                            </div>

                            <div className="review-dropdown"> 
                                <Dropdown overlay={menu}>
                                <span onClick={e => e.preventDefault()}>
                                <Space>
                                    All selected
                                    <DownOutlined className="review-dropdown-icon"/>
                                </Space>
                                </span>
                                </Dropdown>
                            </div>

                            <div className="review-dropdown"> 
                                <Dropdown overlay={menu}>
                                <span onClick={e => e.preventDefault()}>
                                <Space>
                                    All selected
                                    <DownOutlined className="review-dropdown-icon"/>
                                </Space>
                                </span>
                                </Dropdown>
                            </div>
                        </div>
                    </div>

                    <div className="review-recent">
                        <div className="review-recent-checkbox"> 
                            <Checkbox onChange={onChange}>All</Checkbox>
                        </div>

                        <div className="review-dropdown-search">
                            <div className="review-dropdown rec"> 
                                    <Dropdown overlay={menu}>
                                    <span onClick={e => e.preventDefault()}>
                                    <Space>
                                        Recent Reviews
                                        <DownOutlined className="review-dropdown-icon"/>
                                    </Space>
                                    </span>
                                    </Dropdown>
                            </div>
                            <img src={SearchIcon} alt="search" style={{width: '28px', height: '27px', paddingLeft: '12px', paddingTop: '10px', cursor: 'pointer'}}/>
                        </div>

                    </div>

                    <div className="review-details">
                        <div className="review-details-header"> 
                            <div className="review-details-check">
                                <Checkbox onChange={onChange}></Checkbox>
                                <div className="ratings">
                                    <p className="ratings-number">5</p>
                                    <img src={Rating} alt="search" style={{ paddingRight: '6px'}}/>
                                </div>
                                <p className="review-details-text">On <span style={{fontWeight: 'bold'}}>sephora</span>, October 29 2021</p>
                            </div>

                            <div className="review-locate">
                            <p className="review-location">Birmingham, AL</p>
                            </div>
                        </div>


                        <div className="review-message">
                            <div className="review-message-img">
                                <img src={Frame1} alt="frame"/>
                                <p className="review-message-text">Sophia Isabella</p>
                            </div>

                            <p className="review-message-msg">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat sagittis id in massa. Dignissim mauris mi nullam arcu, lobortis at. Placerat <br />
                                vitae elementum vel suscipit vitae molestie vestibulum. Convallis mattis eu amet turpis elementum.
                            </p>

                            <div className="review-message-icon">
                                <div className="review-message-icon-view">
                                    <img src={ViewMore} alt="viewmore"/>
                                    <p>More</p>
                                </div>

                                <div className="review-message-icon-wrap">
                                    <div className="review-message-icon-view">
                                        <img src={ticket} alt="ticket" style={{paddingLeft: '8px'}}/>
                                        <p className="review-message-icon-viewp">Ticket</p>
                                    </div>
                                    <div className="review-message-icon-view">
                                        <img src={reply} alt="reply"/>
                                        <p>reply</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="horizontal"/>
                        <div className="review-message">
                            <div className="review-message-img">
                                <img src={Frame2} alt="frame"/>
                                <p className="review-message-text">Emma Charlotte</p>
                            </div>

                            <p className="review-message-msg">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat sagittis id in massa. Dignissim mauris mi nullam arcu, lobortis at. Placerat <br />
                                vitae elementum vel suscipit vitae molestie vestibulum. Convallis mattis eu amet turpis elementum.
                            </p>

                            <div className="review-message-icon">
                                <div className="review-message-icon-view">
                                    <img src={ViewMore} alt="viewmore"/>
                                    <p>More</p>
                                </div>

                                <div className="review-message-icon-wrap">
                                    <div className="review-message-icon-view">
                                        <img src={ticket} alt="ticket" style={{paddingLeft: '8px'}}/>
                                        <p className="review-message-icon-viewp">Ticket</p>
                                    </div>
                                    <div className="review-message-icon-view">
                                        <img src={reply} alt="reply"/>
                                        <p>reply</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> 
        </div>
    )
}

export default Review;