import './editCategory.scss';
import React, {useState} from "react";
import HeaderItemSelect from "../../../components/Base/HeaderitemSelect/HeaderItemSelect";
import { Input } from 'antd';
import SuggestedKeywords from "../../../components/Insights/suggestedKeywords";
import MainNavbar from "../../../components/Layout/MainNavbar/MainNavbar";

const { TextArea } = Input;

const EditCategory = () => {
    const [categoryName, setCategoryName] = useState("Beauty")
    const [categoryKeywords, setCategoryKeywords] = useState("")
    return (
        <div>
        <MainNavbar   head={"Setup"}  text={<HeaderItemSelect activeItem="insights" />}/>
            <div className="edit-category-page">

                <div className="top-bar">
                    <div className="top-bar__head">
                        Edit Category
                    </div>
                </div>
                <div className="form">
                    <div className="form__group">
                        <label className="form__label" htmlFor="category">Category</label>
                        <Input
                            placeholder={""}
                            name={"category"}
                            value={categoryName}
                            className="form__input form__input--name"
                            onChange={(e)=>setCategoryName(e.target.value)}
                        />
                    </div>
                    <div className="form__group">
                        <label className="form__label" htmlFor="category">Keywords</label>
                        <TextArea
                            placeholder={"Add Keywords"}
                            rows={5}
                            value={categoryKeywords}
                            onChange={(e)=>setCategoryKeywords(e.target.value)}
                        />
                    </div>
                    <div className="form__group">
                        <label className="form__label" htmlFor="category"></label>
                        <button className="btn btn-black">Apply</button>
                    </div>
                </div>
                <SuggestedKeywords />
            </div>

        </div>
    )
}

export default EditCategory;