import React from "react";
import {Select,} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
     faCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import './FilterReview.scss'

const { Option } = Select;


const FilterReviewShort = () => {
    return (
        <div className="filter-review-component">
            <div className="filter">
                <div className="filter__head">
                    <div className="filter__type">
                        <div className="filter__type--item selected">
                            Basic
                        </div>
                        <div className="filter__type--item">
                            Advanced
                        </div>
                        <div className="filter__type--item">
                            Saved (15)
                        </div>
                    </div>
                    <div className="filter__clear">
                        <button className="btn text-btn clearform">
                            <span style={{ paddingRight: '5px' }}>clear</span>
                            <FontAwesomeIcon icon={faCircleXmark} size={"xs"} />
                        </button>
                    </div>
                </div>
                <div className="filter__items">
                    <div className="form">
                        <div className="form__group">
                            <label htmlFor="" className="form__label">Location</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Locations</Option>
                                <Option value="others">others</Option>
                            </Select>
                        </div>
                        <div className="form__group">
                            <label htmlFor="" className="form__label">Review Source</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Selected</Option>
                                <Option value="others">others</Option>
                            </Select>
                        </div>
                        <div className="form__group">
                            <label htmlFor="" className="form__label">Time</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Selected</Option>
                                <Option value="others">others</Option>
                            </Select>
                        </div><div className="form__group">
                        <label htmlFor="" className="form__label">Rating</label>
                        <Select defaultValue="all" className="form__select">
                            <Option value="all">All Selected</Option>
                            <Option value="others">others</Option>
                        </Select>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterReviewShort;