import './MainNavbar.scss'
import React from "react";
import { Dropdown, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDown,
    faLifeRing,
    faUser,
    faArrowRightFromBracket
} from "@fortawesome/free-solid-svg-icons";

interface MainNavbarPropsI {
    head:  string | React.ReactNode;
    text:  string | React.ReactNode;
}

const menu = (
    <Menu
        items={[
            {
                key: '1',
                label: (
                    <a href="/#"  className="navbar__dropdown--item">
                        <FontAwesomeIcon icon={faLifeRing} style={{ marginRight: '15px' }} />
                        Setup
                    </a>
                ),
            },
            {
                key: '2',
                label: (
                    <a href="/#" className="navbar__dropdown--item">
                        <FontAwesomeIcon icon={faUser} style={{ marginRight: '15px' }} />
                        Account
                    </a>
                ),
            },
            {
                key: '3',
                label: (
                    <a  href="/#"  className="navbar__dropdown--item">
                        <FontAwesomeIcon icon={faArrowRightFromBracket} size="lg" style={{ marginRight: '15px' }} />
                        Logout
                    </a>
                ),
            },
        ]}
    />
);

const MainNavbar = ({ head, text }: MainNavbarPropsI) => {


    return (
        <div className="navbar">
            <div className="navbar__left">
                {/*<FontAwesomeIcon icon={faBars} size="lg" />*/}
                <div className="navbar__text">
                    <div className="navbar__text--head">
                        {head}
                    </div>
                    <div className="navbar__text--text">
                        {text}
                    </div>
                </div>
            </div>
            <Dropdown overlay={menu} placement="bottomRight" overlayStyle={{width : "195px", background: "red"}}>
                <div className="navbar__right">
                <FontAwesomeIcon icon={faUser} size="lg" />
                <div className="navbar__user--name-group">
                    <div className="">
                        <span className="navbar__user--name">
                            Tahir R.
                        </span>
                        <FontAwesomeIcon icon={faAngleDown} size="xs" />
                    </div>
                    <div className="navbar__user--designation">
                        Admin
                    </div>
                </div>
            </div>
            </Dropdown>
        </div>
    );
};

export default MainNavbar;
