import React from 'react'
import Chart from '../../assets/images/Chart.png';
import '../review.scss';
import FilterReview from "../../components/Filters/FilterReview";
import HeaderItemSelectRSNT from "../../components/Base/HeaderitemSelectRSNT/HeaderItemSelectRSNT";
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";

const trafficReport = () => {
  return (
    <div>
        <MainNavbar  head={"Review and Ratings over time"} text={<HeaderItemSelectRSNT activeItem={"traffic"} />}/>
         <FilterReview />

        <div className="conversation-container">
          <div className="conversation-wrap">
            <p className="conversation-title">Visitors (Useres who read reviews)</p>
          </div>

          <div className="conversation-chart">
              <img src={Chart} alt="Chart" />
          </div>
        </div>

    </div>
  )
}

export default trafficReport