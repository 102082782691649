import React from "react";
import {Col, Row,   } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircle
} from "@fortawesome/free-solid-svg-icons";
import './suggestedKeywords.scss'

const recommended = [
    {
        item: "Moisturizer",
        recommendationCount: 45
    },{
        item: "Face oil",
        recommendationCount: 50
    },{
        item: "Face serum",
        recommendationCount: 23
    },{
        item: "Beauty cream",
        recommendationCount: 34
    },{
        item: "Cleanser",
        recommendationCount: 86
    },{
        item: "Face mask",
        recommendationCount: 236
    },{
        item: "Anti-aging cream",
        recommendationCount: 15
    },{
        item: "Night Cream",
        recommendationCount: 86
    },{
        item: "Sephora",
        recommendationCount: 45
    },
]

const suggestedKeywords = () => {
    return (
        <div className="suggestedKeywordComponent">
            <Row>
                <Col span={12}>
                    <div className="keywords">
                        <div className="keywords__head">
                            Keywords
                        </div>
                        <Row>
                            <Col span={12}>
                                <div className="keyword__group">
                                    {
                                        recommended.map((item, index)=>(
                                            <div className="keyword__group--item" key={index} style={{ marginBottom: 20 }}>
                                                <FontAwesomeIcon icon={faCircle} style={{ color: '#FD392B', paddingRight: 10 }} />
                                                {item.item}
                                            </div>
                                        ))
                                    }
                                </div>

                            </Col>
                            <Col span={12}>
                                <div className="keyword__group">
                                    {
                                        recommended.map((item, index)=>(
                                             <div className="keyword__group--item" key={index} style={{ marginBottom: 20 }}>
                                                <FontAwesomeIcon icon={faCircle} style={{ color: '#32B67D', paddingRight: 10 }} />
                                                {item.item}
                                            </div>
                                        ))
                                    }
                                </div>
                            </Col>
                        </Row>
                        <div className="recommended__btn">
                            <button className="btn text-btn">
                                Show all
                            </button>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="recommended">
                        <div className="recommended__head">
                            Recommended
                        </div>
                        <div className="recommended__group">
                            {
                                recommended.map((item, index)=>(
                                    <div className="recommended__item" key={index}>
                                        {item.item}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="recommended__btn">
                            <button className="btn text-btn">
                                Add all
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default suggestedKeywords;