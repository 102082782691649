import  './ratingsOverTime.scss'
import HeaderItemSelect from "../../components/Base/HeaderitemSelect/HeaderItemSelect";
import React from "react";
import FilterReviewFull from "../../components/Filters/FilterReviewFull";
import {Line} from "react-chartjs-2";
import {generateRandomInt} from "../../utils";
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: true,
            text: 'Reviews Rating',
        },
    },
    scales: {
        x: {
            display: false,
        },
        y: {
            beginAtZero: true,
            max: 5,
            ticks: {
                stepSize: 1,
            }
        },
    },
};
const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const data = {
    labels,
    datasets: [
        {
            label: 'Amazon',
            data: labels.map(() => parseFloat((generateRandomInt(1500, 4500)/1000).toFixed(2))),
            borderColor: 'rgba(61, 156, 115, 1)',
            backgroundColor: 'rgba(61, 156, 115, 1)',
            lineTension: 0.5,
        },
        {
            label: 'Walmart',
            data: labels.map(() => parseFloat((generateRandomInt(1500, 4500)/1000).toFixed(2))),
            borderColor: 'rgba(253, 57, 43, 1)',
            backgroundColor: 'rgba(253, 57, 43, 1)',
            lineTension: 0.5,
        },
        {
            label: 'Sephora',
            data: labels.map(() => parseFloat((generateRandomInt(1500, 4500)/1000).toFixed(2))),
            borderColor: 'rgba(0, 0, 0, 1)',
            backgroundColor: 'rgba(0, 0, 0, 1)',
            lineTension: 0.5,
        },
    ],
};

const ReviewsOverTime = () => {

    return (
        <div>
        <MainNavbar   head={"Reviews over time "} text={<HeaderItemSelect activeItem="overview" />}/>
            <div className="ratings-over-time">
                <FilterReviewFull />

                <div className="graph">
                    <Line
                        // @ts-ignore
                        options={options}
                        // @ts-ignore
                        data={data}
                    />
                </div>
            </div>
        </div>
    )
}

export default ReviewsOverTime;