import {
    faAngleDown,
    faEllipsisVertical
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import  './categoryByPerformance.scss'
import HeaderItemSelect from "../../../components/Base/HeaderitemSelect/HeaderItemSelect";
import {Table} from 'antd';
import React, {useState} from "react";
import FilterReview from "../../../components/Filters/FilterReview";
import MainNavbar from "../../../components/Layout/MainNavbar/MainNavbar";


const navbarHead = (
    <div className="topItem">
        <span className="topItem__text">Setup</span>
        <FontAwesomeIcon icon={faAngleDown} size={"xs"} />
    </div>
)

const tableData = [
    {
        location: "Lancaster CA",
        overall: "A",
        quality: "A",
        rating: "A+",
        value: "D",
        pricing: "B+",
    },{
        location: "ABredeen SD",
        overall: "B-",
        quality: "C",
        rating: "A+",
        value: "D",
        pricing: "B+",
    },{
        location: "Austin, TX",
        overall: "B-",
        quality: "C",
        rating: "A+",
        value: "D",
        pricing: "B+",
    },{
        location: "Bangor, ME",
        overall: "B-",
        quality: "C",
        rating: "A+",
        value: "D",
        pricing: "B+",
    },{
        location: "Billings, MT",
        overall: "B-",
        quality: "C",
        rating: "A+",
        value: "D",
        pricing: "B+",
    },{
        location: "Birmingham, ML",
        overall: "B-",
        quality: "C",
        rating: "A+",
        value: "D",
        pricing: "B+",
    },{
        location: "Lancaster, CA",
        overall: "A",
        quality: "A",
        rating: "A+",
        value: "D",
        pricing: "B+",
    },{
        location: "ABredeen SD",
        overall: "A",
        quality: "A",
        rating: "A+",
        value: "D",
        pricing: "B+",
    }
]

const columns = [
    {
        title: 'Location',
        dataIndex: 'location',
        sorter: (a: any, b: any) => a.location.localeCompare(b.location),
        key: 'location'
    },
    {
        title: 'Overall',
        dataIndex: 'overall',
        key: 'overall',
        sorter: (a: any, b: any) => a.overall.localeCompare(b.overall),
        render: (val: any)=>(<span className={"btn pill-btn overall-btn " + (val === 'A' ? 'green' :'faded-green')}>{val}</span>),
    },
    {
        title: 'Quality',
        dataIndex: 'quality',
        key: 'quality',
        sorter: (a: any, b: any) => a.quality.localeCompare(b.quality),
        render: (val: any)=>(<span className={"btn pill-btn overall-btn " + (val === 'A' ? 'green' :'faded-red')}>{val}</span>),
    },

    {
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating',
        sorter: (a: any, b: any) => a.rating.localeCompare(b.rating),
        render: (val: any)=>(<span className={"btn pill-btn overall-btn green"}>{val}</span>),
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: (a: any, b: any) => a.value.localeCompare(b.value),
        render: (val: any)=>(<span className={"btn pill-btn overall-btn red"}>{val}</span>),
    },{
        title: 'Pricing',
        dataIndex: 'pricing',
        key: 'pricing',
        sorter: (a: any, b: any) => a.pricing.localeCompare(b.pricing),
        render: (val: any)=>(<span className={"btn pill-btn overall-btn faded-green"}>{val}</span>),
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     render: ()=>(<span className="btn btn-black btn-rounded status-btn">Active</span>),
    // },
]


const CategoriesByPerformance = () => {
    const [data, ] = useState(tableData);

    return (
        <div>
        <MainNavbar   head={navbarHead} text={<HeaderItemSelect activeItem="location" />}/>
            <div className="category-by-performance cbp">
                <div className="top-bar">
                    <div className="top-bar__head">
                        Category Performance by Location
                    </div>
                    <div className="top-bar__button">
                        <FontAwesomeIcon icon={faEllipsisVertical} size={"lg"} />
                    </div>
                </div>

                <FilterReview />


                <div className="table">
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default CategoriesByPerformance;