import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, PointElement, LineElement,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { generateRandomInt } from "../../utils";
import FilterReview from "../../components/Filters/FilterReview";
import HeaderItemSelectRSNT from "../../components/Base/HeaderitemSelectRSNT/HeaderItemSelectRSNT";
import './reports.scss'
import {useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";

enum visualizationTypeEnm {
    GRAPH="graph",
    TABLE="table"
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
    PointElement,
    LineElement,
);

interface DataType {
    key: React.Key;
    date: string;
    reviewCount: number;
    averageCount: number;
}


const tableColumns: ColumnsType<DataType> = [
    {
        title: 'Date',
        dataIndex: 'date',
    },
    {
        title: 'Review Count',
        dataIndex: 'reviewCount',
        sorter: {
            compare: (a, b) => a.reviewCount - b.reviewCount,
            multiple: 2,
        },
    },
    {
        title: 'Average Count',
        dataIndex: 'averageCount',
        sorter: {
            compare: (a, b) => a.averageCount - b.averageCount,
            multiple: 1,
        },
    },
];

const tableData: DataType[] = [
    {
        key: '1',
        date: 'May, 2022',
        reviewCount: 25987,
        averageCount: 4.2,
    },
    {
        key: '2',
        date: 'Apr, 2022',
        reviewCount: 15684,
        averageCount: 4.1,
    },
    {
        key: '3',
        date: 'Mar, 2022',
        reviewCount: 8684,
        averageCount: 4.0,
    },
    {
        key: '4',
        date: 'Feb, 2022',
        reviewCount: 532,
        averageCount: 3.9,
    },
    {
        key: '5',
        date: 'Jan, 2022',
        reviewCount: 2614,
        averageCount: 4.3,
    },
];


const Reports = () => {
    const [reportType, setReportType] = useState(visualizationTypeEnm.GRAPH)
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                beginAtZero: true,
                max: 5,
                ticks: {
                    stepSize: 1,
                }
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
                max: 5000,
                ticks: {
                    stepSize: 1000,
                }
            },
        },
    };

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const data = {
        labels,
        datasets: [
            {
                type: 'bar' as const,
                label: 'Review Rating',
                data: labels.map(() => generateRandomInt(0, 5)),
                backgroundColor: '#22282F',
                yAxisID: 'y',
            },
            {
                type: 'line' as const,
                fill: true,
                label: 'Number of Reviews',
                data: labels.map(() => generateRandomInt(0, 5000)),
                borderColor: '#E9E9E9',
                backgroundColor: '#E9E9E9',
                yAxisID: 'y1',
                lineTension: 0.25,
            },
        ],
    };

    return (
        <div>
        <MainNavbar   head={"Review and Ratings over time"} text={<HeaderItemSelectRSNT activeItem={"review"} />} />
            <div className="reports">
                <FilterReview />
                <div className="chart-container" >
                    <div className="chart__type">
                        <button
                            className={"btn text-btn " + (reportType === visualizationTypeEnm.GRAPH ? "selected" : "")}
                            onClick={()=>setReportType(visualizationTypeEnm.GRAPH)}
                        >Graph</button>
                        <button
                            className={"btn text-btn " + (reportType === visualizationTypeEnm.TABLE ? "selected" : "")}
                            onClick={()=>setReportType(visualizationTypeEnm.TABLE)}
                        >
                            Table
                        </button>
                    </div>
                    <div className="chart__head">
                        Review and Ratings over time
                    </div>
                    {reportType === visualizationTypeEnm.GRAPH ?
                        <Chart type='bar' options={options} data={data}/>
                        :
                        <Table
                            columns={tableColumns}
                            dataSource={tableData}
                            // onChange={onChange}
                            pagination={false}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default Reports;