import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import './presence.scss'
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";


interface DataType {
  key: string;
  name: string;
  businessName: string;
  address: string;
  phone: string;
  status: string;
  imgURL: string;
}



const columns: ColumnsType<DataType> = [
  {
    title: 'Site name',
    dataIndex: 'imgURL',
    render:  (imgURL) => <img src={imgURL} alt={"partner logo"} style={{ maxHeight: 60 }} />
  },
  {
    title: 'Business name',
    dataIndex: 'businessName',
    key: 'businessName',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const PresenceHeader = (
    <div className="presence__header">
      <div className="presence__header--main">
        Scan my presence
      </div>
      <div className="presence__header--text">
        Fix my presence
      </div>
      <div className="presence__header--button">
        <span className="btnn btn-black">
          33 errors
        </span>
      </div>
    </div>
)

const Presence: React.FC = () => {
  const [data, ] = useState([
    {
      key: '1',
      name: 'John Brown',
      businessName: 'CINTR',
      address: '3310 7th Ave SE',
      phone: '(605) 725-4000',
      status: 'Presence verified',
      imgURL: 'https://drive.google.com/uc?id=1_juz02pPFdCqdP6KxAmxQleC5wf3SPJ5'
    },
    {
      key: '2',
      name: 'Jim Green',
      businessName: 'CINTR',
      address: '3310 7th Ave SE',
      phone: '(605) 725-4000',
      status: 'Presence verified',
      imgURL: 'https://drive.google.com/uc?id=1XNEDziDnm1cOMS0OOXptukfCOYpGIShV'
    },
    {
      key: '3',
      name: 'Joe Black',
      businessName: 'CINTR',
      address: '3310 7th Ave SE',
      phone: '(605) 725-4000',
      status: 'Presence verified',
      imgURL: 'https://drive.google.com/uc?id=1rJRa-fLlyMnTxCpt0dRtSaaCZm1n1nY7'
    },
    {
        key: '4',
        name: 'Joe Black',
        businessName: 'CINTR',
        address: '3310 7th Ave SE',
        phone: '(605) 725-4000',
        status: 'Presence verified',
        imgURL: 'https://drive.google.com/uc?id=1beIpmLwcjqNGpRHw-LQOaQlKUJoYuVx-'
      },
      {
        key: '5',
        name: 'Joe Black',
        businessName: 'CINTR',
        address: '3310 7th Ave SE',
        phone: '(605) 725-4000',
        status: 'Presence verified',
        imgURL: 'https://drive.google.com/uc?id=1PhFYOTKttFl6Qd_Qod-f-yGS9xBe7bDF'
      },
      {
        key: '6',
        name: 'Joe Black',
        businessName: 'CINTR',
        address: '3310 7th Ave SE',
        phone: '(605) 725-4000',
        status: 'Presence verified',
        imgURL: 'https://drive.google.com/uc?id=1_juz02pPFdCqdP6KxAmxQleC5wf3SPJ5'
      },
      {
        key: '7',
        name: 'Joe Black',
        businessName: 'CINTR',
        address: '3310 7th Ave SE',
        phone: '(605) 725-4000',
        status: 'Presence verified',
        imgURL: 'https://drive.google.com/uc?id=1XNEDziDnm1cOMS0OOXptukfCOYpGIShV'
      },
      {
        key: '8',
        name: 'Joe Black',
        businessName: 'CINTR',
        address: '520 3rd St NW',
        phone: 'not found',
        status: 'not found',
        imgURL: 'https://drive.google.com/uc?id=1rJRa-fLlyMnTxCpt0dRtSaaCZm1n1nY7'
      },
  ]);

  const components = {
    body: {
    },
  };


  return (
      <div>
        <MainNavbar head={"Your online presence"}  text={PresenceHeader} />
        <div className="container presence-page">
          <Table
            columns={columns}
            dataSource={data}
            components={components}
            pagination={false}
          />
        </div>
      </div>
  );
};

export default Presence; 
