import './editCategory.scss';
import React, {useState} from "react";
import HeaderItemSelect from "../../../components/Base/HeaderitemSelect/HeaderItemSelect";
import { Input, } from 'antd';
import SuggestedKeywords from "../../../components/Insights/suggestedKeywords";
import MainNavbar from "../../../components/Layout/MainNavbar/MainNavbar";

const { TextArea } = Input;

const EditCategory = () => {
    const [categoryKeywords, setCategoryKeywords] = useState("")
    return (
        <div>
        <MainNavbar   head={"Setup"}  text={<HeaderItemSelect activeItem="insights" />}/>
            <div className="edit-category-page">
                <div className="top-bar">
                    <div className="top-bar__head">
                        Setup Keywords and Adjectives
                    </div>
                </div>
                <div className="form">
                    <div className="form__group">
                        <label className="form__label" htmlFor="submit-btn"></label>
                        <div className="form__selected">
                            <div className="form__selected--item selected">Whitelisted keyword</div>
                            <div className="form__selected--item">Blacklisted keyword</div>
                            <div className="form__selected--item">Whitelisted Adjective</div>
                            <div className="form__selected--item">Blacklisted Adjectives</div>
                        </div>
                    </div>
                    <div className="form__group">
                        <label className="form__label" htmlFor="category">Keywords</label>
                        <TextArea
                            placeholder={"Add Keywords"}
                            rows={5}
                            value={categoryKeywords}
                            onChange={(e)=>setCategoryKeywords(e.target.value)}
                        />
                    </div>
                    <div className="form__group">
                        <label className="form__label" htmlFor="submit-btn"></label>
                        <button className="btn btn-black" name="submit-btn">Apply</button>
                    </div>
                </div>
                <SuggestedKeywords />
            </div>

        </div>
    )
}

export default EditCategory;