import HeaderItemSelect from "../../components/Base/HeaderitemSelect/HeaderItemSelect";
import React, {useState} from "react";
import FilterReview from "../../components/Filters/FilterReview";
import './competitivePerformanceByCategoryAlt.scss';
import LinearProgressBar from "../../components/Base/LinearProgressBar";
import {Checkbox} from "antd";
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";

const keywordMentions = [
    {
        name: "moisturizer",
        score: 25987,
        checked: false,
    },
    {
        name: "Cleanser",
        score: 15684,
        checked: false,
    },
    {
        name: "Face oil",
        score: 8684,
        checked: false,
    },
    {
        name: "Sephora",
        score: 2614,
        checked: false,
    },
    {
        name: "Anti-aging cream",
        score: 532,
        checked: false,
    },
    {
        name: "Face serum",
        score: 8684,
        checked: false,
    }
]

const defaultSentiments = [
    {
        head: "Sentiment C, 24 mentions",
        address: "Colorado Spring, CO",
        dateLocation: "November 3, 2016 Angel Barnes, Sephora",
        text: "Nice Moisturizer, and it’s very hydrating Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        showFullText: false,
    },
    {
        head: "Sentiment C, 24 mentions",
        address: "Colorado Spring, CO",
        dateLocation: "November 3, 2016 Angel Barnes, Sephora",
        text: "Nice Moisturizer, and it’s very hydrating Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        showFullText: false,
    },
    {
        head: "Sentiment C, 24 mentions",
        address: "Colorado Spring, CO",
        dateLocation: "November 3, 2016 Angel Barnes, Sephora",
        text: "Nice Moisturizer, and it’s very hydrating Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        showFullText: false,
    },

]

const CompetitivePerformanceByCategoryAlt = () => {
    const [keywordRanking, setKeywordRanking] = useState(keywordMentions)
    const [sentiments, setSentiments] = useState(defaultSentiments)

    const checkThisKeyword = (index: number) => {
        const newKeywordRanking = [...keywordRanking];
        newKeywordRanking[index].checked = !newKeywordRanking[index].checked
        setKeywordRanking(newKeywordRanking)
    }

    const toggleShowFullText = (index: number) => {
        const newSentiments = [...sentiments];
        newSentiments[index].showFullText = !newSentiments[index].showFullText
        setSentiments(newSentiments)
    }

    return (
        <div>
        <MainNavbar   head={"Competitive Performance by Category"} text={<HeaderItemSelect activeItem="category" />}/>
            <div className="competitive-performance-by-category-alt">
                <FilterReview />
                <div className="sentiment-filter sf">
                    <div className="filter">
                        <div className="filter__types">
                            <div className="filter__type--item selected">
                                All
                            </div>
                            <div className="filter__type--item">
                                Positive
                            </div>
                            <div className="filter__type--item">
                                Negative
                            </div>
                        </div>
                        <div className="filter__head">
                            Filter by keywords
                        </div>
                        <div className="filter__items">
                            {
                                keywordRanking && keywordRanking.length > 0 ?
                                    keywordRanking.map((keyword, index)=>(
                                        <div className="filter__item">
                                            <div className="filter__item--text">
                                                <Checkbox
                                                    onChange={()=>checkThisKeyword(index)}
                                                >
                                                    {keyword.name}
                                                </Checkbox>
                                            </div>
                                            <div className="filter__item--progress">
                                                <LinearProgressBar
                                                    label={`${keyword.score}`}
                                                    score={keyword.score}
                                                    total={27000}
                                                />
                                            </div>
                                        </div>
                                    ))
                                    : <></>
                            }
                        </div>
                    </div>
                    <div className="sentiment">
                        <div className="sentiment__category">
                            <div className="sentiment__category--item selected">Snipped</div>
                            <div className="sentiment__category--item">Full review</div>
                        </div>
                        {
                            sentiments && sentiments.length > 0 ?
                                sentiments.map((sent, index)=>(
                                    <div className="sent">
                                        <div className="sent__head">
                                            {sent.head}
                                        </div>
                                        <div className="sent__address">
                                            {sent.address}
                                        </div>
                                        <div className="sent__date-location">
                                            {sent.dateLocation}
                                        </div>
                                        <div className="sent__review">
                                            {sent.showFullText ?
                                                <div>
                                                    {sent.text} <button className="btn text-btn more-btn" onClick={()=>toggleShowFullText(index)}>Less</button>
                                                </div>
                                                :
                                                <div>
                                                    {sent.text.substring(0, 31)} ...<button className="btn text-btn more-btn" onClick={()=>toggleShowFullText(index)}> more</button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                ))
                                : <></>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompetitivePerformanceByCategoryAlt;