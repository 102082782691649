import React from "react";
import HeaderItemSelect from "../../../components/Base/HeaderitemSelect/HeaderItemSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAngleDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import './setupCategories.scss';
import MainNavbar from "../../../components/Layout/MainNavbar/MainNavbar";


const navbarHead = (
    <div className="topItem">
        <span className="topItem__text">Setup</span>
        <FontAwesomeIcon icon={faAngleDown} size={"xs"} />
    </div>
)


const setupCategories = () => {

    return (
        <div>
        <MainNavbar   head={navbarHead} text={<HeaderItemSelect activeItem="insights" />}/>
            <div className="setup-category-page">
                <div className="top-bar">
                    <div className="top-bar__head">
                        Setup Categories
                    </div>
                    <div className="top-bar__button">
                        <button className="btn btn-black">
                            Add a category
                        </button>
                    </div>
                </div>
                <div className="setup__group">
                    <div className="setup__group--item sgi">
                        <div className="sgi__head">
                            <div className="sgi__head--text">
                                Beauty
                            </div>
                            <div className="sgi__head--actions">
                                <button className="btn text-btn eit-btn">
                                    Edit
                                </button>
                                <button className="btn text-btn delete-btn">
                                    Delete all
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <div className="sgi__body">
                                <span className="sgi__body--item">
                                    Moisturizer
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                                <span className="sgi__body--item">
                                    Night Cream
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                                <span className="sgi__body--item">
                                    Anti aging cream
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                                <span className="sgi__body--item">
                                    Sephore
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="setup__group--item sgi">
                        <div className="sgi__head">
                            <div className="sgi__head--text">
                                Health
                            </div>
                            <div className="sgi__head--actions">
                                <button className="btn text-btn eit-btn">
                                    Edit
                                </button>
                                <button className="btn text-btn delete-btn">
                                    Delete all
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <div className="sgi__body">
                                <span className="sgi__body--item">
                                    Ensure Pls
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                                <span className="sgi__body--item">
                                    Ensure
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                                <span className="sgi__body--item">
                                    Ensure products
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="setup__group--item sgi">
                        <div className="sgi__head">
                            <div className="sgi__head--text">
                                Technology
                            </div>
                            <div className="sgi__head--actions">
                                <button className="btn text-btn eit-btn">
                                    Edit
                                </button>
                                <button className="btn text-btn delete-btn">
                                    Delete all
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <div className="sgi__body">
                                <span className="sgi__body--item">
                                    iPhone
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                                <span className="sgi__body--item">
                                    Apple airpods
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                                <span className="sgi__body--item">
                                    Apple Macbook
                                    <FontAwesomeIcon icon={faXmark} style={{ paddingLeft: 10, }} />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="setup__group--btn">
                        <button className="btn text-btn">
                            Add all
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default setupCategories;