import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDown,
    faBan,
    faEllipsisVertical,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import HeaderItemSelect from "../../components/Base/HeaderitemSelect/HeaderItemSelect";
import React, {useState} from "react";
import {Dropdown, Menu, Table} from "antd";
import './setup.scss'
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";

const navbarHead = (
    <div className="topItem">
        <span className="topItem__text">Setup</span>
        <FontAwesomeIcon icon={faAngleDown} size={"xs"} />
    </div>
)

const tableData = [
    {
        locationId: "890262345",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },
    {
        locationId: "890262345",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },
    {
        locationId: "1035",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },
    {
        locationId: "890262345",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },
    {
        locationId: "47318",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },
    {
        locationId: "890262345",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },{
        locationId: "23828",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },{
        locationId: "890262345",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },{
        locationId: "5989",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },{
        locationId: "890262345",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },{
        locationId: "1022",
        alias: "Lancaster CA",
        createdOn: "April 24, 2015",
        createdBy: "Frances Moody",
    },
]

const columns = [
    {
        title: 'Location ID',
        dataIndex: 'locationId',
        key: 'locationId'
    },
    {
        title: 'Alias',
        dataIndex: 'alias',
        key: 'alias',
    },
    {
        title: 'Created On',
        dataIndex: 'createdOn',
        key: 'createdOn',
    },

    {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: ()=>(<span className="btn btn-black btn-rounded status-btn">Active</span>),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: ()=>(<Dropdown overlay={actionMenu}>
                <span>
                    <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
                </span>
            </Dropdown>),
    },
]

const actionMenu = (
    <Menu
        items={[
            {
                key: '1',
                label: (
                    <div className="table-deactivate-btn">
                        <FontAwesomeIcon icon={faBan} />
                        <span className="table-action-text">Deactivate</span>
                    </div>
                )
            },
            {
                key: '2',
                label: (
                    <div className="table-delete-btn">
                        <FontAwesomeIcon icon={faTrashCan} />
                        <span className="table-action-text">Delete</span>
                    </div>
                )
            }
        ]}
    />
);

const Setup = () => {
    const [data, ] = useState(tableData);

    return (
        <div>
            <MainNavbar head={navbarHead} text={<HeaderItemSelect activeItem={"location"} />} />
            <div className="setup-page">

                <div className="top-bar">
                    <div className="top-bar__head">
                        Insights
                    </div>
                    <div className="top-bar__button">
                        <button className="btn btn-black">
                            Add a category
                        </button>
                        <button className="btn text-btn">
                            See all
                        </button>
                    </div>

                </div>

                <div className="table">
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default Setup;