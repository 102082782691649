import React from 'react';
import ProgressBar from "../../components/Base/ProgressBar";
import {Col, Row, Select} from 'antd';
import './overview.scss'
import LinearProgress from "../../components/Base/LinearProgressBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";


const { Option } = Select;

const Overview = () => {
    return  (
        <div>
            <MainNavbar head={"Overview"} text={"Detailed information about your website"} />
            <div className="overview">
                <Row>
                    <Col span={18}>
                        <div>
                            <div>
                                <div className="search-appearance sa">
                                    <div className="sa__text" style={{ marginBottom: 35 }}>
                                        You received
                                        <span style={{ fontWeight: 700}}> 23 reviews </span>
                                        in the past 30 days
                                    </div>
                                    <div className="sa__ratings sa__ratings__top">
                                        <div className="sa__ratings--item">
                                            <ProgressBar
                                                label={"User Reviews (22)"}
                                                rating={4.69}
                                            />
                                        </div>
                                        <div className="sa__ratings--item">
                                            <ProgressBar
                                                label={"Best Seller (15)"}
                                                rating={3.3}
                                            />
                                        </div>
                                        <div className="sa__ratings--item">
                                            <ProgressBar
                                                label={"Industry Recognition (10)"}
                                                rating={4.0}
                                            />
                                        </div>
                                        <div className="sa__ratings--item">
                                            <ProgressBar
                                                label={"Social Buzz"}
                                                rating={1.0}
                                            />
                                        </div>
                                    </div>

                                    <div className="sa__ratings sa__ratings--bottom">
                                        <div className="sa__ratings--item">
                                            <ProgressBar
                                                label={"Final Score"}
                                                rating={4.3}
                                                strokeWidth={10}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="top__reviewer">
                                <div className="top__reviewer--head">
                                    Top 5 Review Sources
                                </div>
                                <div className="tr__items">
                                    <div className="tr__item">
                                        <div className="tr__item--text">Amazon</div>
                                        <div className="tr__item--rating">
                                            <LinearProgress label={"(121)"} score={121} total={521}  trailColor={"#FFFFFF00"} flatEdges={true}/>
                                        </div>
                                    </div>
                                    <div className="tr__item">
                                        <div className="tr__item--text">Nordstorm</div>
                                        <div className="tr__item--rating">
                                            <LinearProgress label={"(281)"} score={281} total={521}  trailColor={"#FFFFFF00"} flatEdges={true}/>
                                        </div>
                                    </div>
                                    <div className="tr__item">
                                        <div className="tr__item--text">Ulta Beauty</div>
                                        <div className="tr__item--rating">
                                            <LinearProgress label={"(84)"} score={84} total={521}  trailColor={"#FFFFFF00"} flatEdges={true}/>
                                        </div>
                                    </div>
                                    <div className="tr__item">
                                        <div className="tr__item--text">Sephora</div>
                                        <div className="tr__item--rating">
                                            <LinearProgress label={"(520)"} score={520} total={521}  trailColor={"#FFFFFF00"} flatEdges={true}/>
                                        </div>
                                    </div>
                                    <div className="tr__item">
                                        <div className="tr__item--text">Walmart</div>
                                        <div className="tr__item--rating">
                                            <LinearProgress label={"(30)"} score={30} total={521}  trailColor={"#FFFFFF00"} flatEdges={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="report">
                                <div className="report__head">
                                    Critical Reviews
                                </div>
                                <div className="report__text">
                                    <span style={{ fontWeight: 'bold' }}> 10 reviews </span> were 3 stars or less in the past 30 days
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="overview__sidebar">
                            <div className="filter">
                                <div className="filter__type">
                                    <div className="filter__type--item">
                                        Basic
                                    </div>
                                    <div className="filter__type--item">
                                        Advanced
                                    </div>
                                    <div className="filter__type--item">
                                        Saved (15)
                                    </div>
                                </div>
                                <div className="filter__form">
                                    <div className="filter__form--clear-btn">
                                        <button className="btn text-btn clearform">
                                            <span style={{ paddingRight: '5px' }}>clear</span>
                                            <FontAwesomeIcon icon={faCircleXmark} size={"xs"} />
                                        </button>
                                    </div>
                                    <div className="filter__form--item">
                                        <div className="filter__label">Location</div>
                                        <Select defaultValue="all" style={{ width: 120 }} allowClear>
                                            <Option value="all">All Selected</Option>
                                        </Select>
                                    </div>
                                    <div className="filter__form--item">
                                        <div className="filter__label">Review Source</div>
                                        <Select defaultValue="all" style={{ width: 120 }} allowClear>
                                            <Option value="all">All Selected</Option>
                                        </Select>
                                    </div>
                                    <div className="filter__form--item">
                                        <div className="filter__label">Time</div>
                                        <Select defaultValue="all" style={{ width: 120 }} allowClear>
                                            <Option value="all">All Selected</Option>
                                        </Select>
                                    </div>
                                    <div className="filter__form--item">
                                        <div className="filter__label">Rating</div>
                                        <Select defaultValue="all" style={{ width: 120 }} allowClear>
                                            <Option value="all">All Selected</Option>
                                        </Select>
                                    </div>
                                    <div className="filter__form--item">
                                        <button className="btn btn-black">
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Overview;