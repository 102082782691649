import React from "react";
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
// Layout Types
// import { DefaultLayout } from "../layouts";

import { DefaultLayout } from "../layouts";
import CategoriesByPerformance from "../pages/insights/categoryByPerformance/categoryByPerformance";
import CompetitiveRanking from "../pages/competitors/competitiveRanking";
import ReviewsOverTime from "../pages/competitors/reviewsOverTime";
import RatingsOverTime from "../pages/competitors/ratingsOverTime";
import CompetitivePerformanceByCategory from "../pages/competitors/competitivePerformanceByCategory";
import CompetitivePerformanceByCategoryAlt from "../pages/competitors/competitivePerformanceByCategoryAlt";
import EditCategory from "../pages/insights/edit/editCategory";
import EditKeywords from "../pages/insights/edit/editKeywords";
import SetupCategories from "../pages/insights/setupCategories/setupCategories";
import SetupKeyword from "../pages/insights/setupKeywords/setupKeywords";
import Insights from "../pages/insights/insights";
import Presence from "../pages/presence/presence";
import Report from "../pages/reports/reports";
import Reviews from "../pages/reviews/reviews";
import Overview from "../pages/dashboard/overview";
import Setup from "../pages/setup/setup";
import ConversationChart from "../pages/reports/conversionCharts/conversionChart";
import TrafficReport from "../pages/reports/trafficReport";
import SentimentReport from "../pages/reports/sentimentsByLocation/sentimentByLocation";
import Social from "../pages/social/social";
import KeywordsAndAdjectives from "../pages/keywordAndAdjectives/keywordsAndAdjectives";
import ReviewBySource from "../pages/reviews/ReviewBySource"





export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route>
                    <Route element={<DefaultLayout><Outlet /></DefaultLayout>}>
                        <Route path="/" element={<Overview />} />
                        <Route path="/categories-by-performance" element={<CategoriesByPerformance />} />
                        <Route path="/competitors/competitive-performance-by-category" element={<CompetitivePerformanceByCategory />} />
                        <Route path="/competitors/competitive-performance-by-category1" element={<CompetitivePerformanceByCategoryAlt />} />
                        <Route path="/competitors" element={<CompetitiveRanking />} />
                        <Route path="/competitors/ratings-over-time" element={<RatingsOverTime />} />
                        <Route path="/competitors/reviews-over-time" element={<ReviewsOverTime />} />
                        <Route path="/insights" element={<Insights />} />
                        <Route path="/insights/edit-category" element={<EditCategory />} />
                        <Route path="/insights/edit-keywords" element={<EditKeywords />} />
                        <Route path="/insights/setup-category" element={<SetupCategories />} />
                        <Route path="/insights/setup-keywords" element={<SetupKeyword />} />
                        <Route path="/presence" element={<Presence />} />
                        <Route path="/reports" element={<Report />} />
                        <Route path="/reports/conversation-chart" element={<ConversationChart />} />
                        <Route path="/reports/sentiment-report" element={<SentimentReport />} />
                        <Route path="/reports/traffic-report" element={<TrafficReport />} />
                        <Route path="/reviews" element={<Reviews />} />
                        <Route path="/reviews/review-by-source" element={<ReviewBySource />} />
                        <Route path="/setup" element={<Setup />} />
                        <Route path="/social" element={<Social />} />

                    </Route>
                </Route>
                <Route path="/keywords-and-adjectives"  element={<KeywordsAndAdjectives />} />
            </Routes>
        </BrowserRouter>
    );
};