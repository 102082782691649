import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import '../review.scss';
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";
import FilterReview from "../../components/Filters/FilterReview";
import HeaderItemSelectRSNT from "../../components/Base/HeaderitemSelectRSNT/HeaderItemSelectRSNT";

interface DataType {
    key: string;
    name: string;
    businessName: string;
    imgURL: string;
  }
  
  
  
  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'imgURL',
      render:  (imgURL) => <img src={imgURL} alt={"sponsor"} style={{ maxHeight: 60 }} />
    },
    {
        title: '',
        dataIndex: 'name',
        key: 'name',
      },
    {
      title: '',
      dataIndex: 'businessName',
      key: 'businessName',
    }
  ];




const ReviewBySource: React.FC = () => {
    const [data,] = useState([
        {
          key: '1',
          name: 'Sephora',
          businessName: '',
          imgURL: 'https://drive.google.com/uc?id=1_juz02pPFdCqdP6KxAmxQleC5wf3SPJ5'
        },
        {
          key: '2',
          name: 'Ulta Beauty',
          businessName: '',
          imgURL: 'https://drive.google.com/uc?id=1XNEDziDnm1cOMS0OOXptukfCOYpGIShV'
        },
        {
          key: '3',
          name: 'Amazon',
          businessName: '',
          imgURL: 'https://drive.google.com/uc?id=1rJRa-fLlyMnTxCpt0dRtSaaCZm1n1nY7'
        },
        {
            key: '4',
            name: 'Walmat',
            businessName: '',
            imgURL: 'https://drive.google.com/uc?id=1beIpmLwcjqNGpRHw-LQOaQlKUJoYuVx-'
          },
          {
            key: '5',
            name: 'Nordstrom',
            businessName: '',
            imgURL: 'https://drive.google.com/uc?id=1PhFYOTKttFl6Qd_Qod-f-yGS9xBe7bDF'
          },
          {
            key: '6',
            name: 'Sephora',
            businessName: '',
            imgURL: 'https://drive.google.com/uc?id=1_juz02pPFdCqdP6KxAmxQleC5wf3SPJ5'
          },
          {
            key: '7',
            name: 'Ulta beauty',
            businessName: '',
            imgURL: 'https://drive.google.com/uc?id=1XNEDziDnm1cOMS0OOXptukfCOYpGIShV'
          },
          {
            key: '8',
            name: 'Amazon',
            businessName: '',
            imgURL: 'https://drive.google.com/uc?id=1rJRa-fLlyMnTxCpt0dRtSaaCZm1n1nY7'
          }
])

  return (
    <div>
      <MainNavbar head={"Review by Source"} text={<HeaderItemSelectRSNT activeItem={"review"} />} />
        <FilterReview />

        <div className="conversation-container">
          <div className="conversation-header">
            <p className="conversation-text">Graph</p>
            <p className="conversation-text">Table</p>
          </div>

          <div className="conversation-wrap">
            <p className="conversation-title">Review by Source</p>
            <p className="conversation-title" style={{paddingRight: '20rem'}}>Review Summary</p>
          </div>

          <div className="conversation-table">
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                />
          </div>

      </div>
    </div>
  )
}

export default ReviewBySource