import  './competitiveRanking.scss'
import HeaderItemSelect from "../../components/Base/HeaderitemSelect/HeaderItemSelect";
import React from "react";
import FilterReviewFull from "../../components/Filters/FilterReviewFull";
import ProgressBar from "../../components/Base/ProgressBar";
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";


const CategoriesByPerformance = () => {

    return (
        <div>
        <MainNavbar   head={"Competitive Ranking"} text={<HeaderItemSelect activeItem="overview" />}/>
            <div className="competitive-ranking">
                <FilterReviewFull />

                <div className="reviews">
                    <div className="review__item">
                        <div className="review__item--head">
                            CINTR
                        </div>
                        <ProgressBar
                            label={"52,318 reviews"}
                            rating={4.5}
                            width={150}
                            strokeWidth={3}
                            innerLabelClassName={"inner-label"}
                            outerLabelClassName={"outer-label"}
                        />
                    </div>
                    <div className="review__item">
                        <div className="review__item--head">
                            Amazon
                        </div>
                        <ProgressBar
                            label={"40,017 reviews"}
                            rating={3.9}
                            width={150}
                            strokeWidth={3}
                            innerLabelClassName={"inner-label"}
                            outerLabelClassName={"outer-label"}
                        />
                    </div>
                    <div className="review__item">
                        <div className="review__item--head">
                            Walmart
                        </div>
                        <ProgressBar
                            label={"29,527 reviews"}
                            rating={4.2}
                            width={150}
                            strokeWidth={3}
                            innerLabelClassName={"inner-label"}
                            outerLabelClassName={"outer-label"}
                        />
                    </div>
                    <div className="review__item">
                        <div className="review__item--head">
                            Sephora
                        </div>
                        <ProgressBar
                            label={"11,928 reviews"}
                            rating={3.5}
                            width={150}
                            strokeWidth={3}
                            innerLabelClassName={"inner-label"}
                            outerLabelClassName={"outer-label"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoriesByPerformance;