import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface sidebarI {
    isSidebarOpen: boolean;
}

const initialState: sidebarI = {
    isSidebarOpen: false
}



export const sidebarSlice = createSlice({
    name: 'sidebarStore',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setShowSidebar: (state, action: PayloadAction<sidebarI> ) => {
            state.isSidebarOpen = action.payload.isSidebarOpen;
        },
        toggleShowSidebar: (state, action)=>{
            state.isSidebarOpen = !state.isSidebarOpen;
        }
    },
});

export default sidebarSlice.reducer;
