import React from "react";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {useAppDispatch} from "../../../redux/store/hooks";
import toggleShowSidebar from "../../../redux/reducers/SidebarReducer";
import { Menu } from 'antd';
import MenuItems from "./MenuItems";

interface MainSidebarPropsI {
  hideLogoText?: boolean;
}


interface MainSidebarPropsI {
  topMenu: boolean,
  changeRtl: (val: boolean)=>void,
  changeLayout: (val: boolean)=>void,
  changeMenuMode: (val: boolean)=>void,
  
}


const MainSidebar = ({ topMenu, changeMenuMode, changeRtl} : MainSidebarPropsI) => {
  const dispatch = useAppDispatch();

  const toggleCollapsedMobile = () => {
    // @ts-ignore
    dispatch(toggleShowSidebar());
  }

  const onRtlChange = () => {
    const html = document.querySelector('html');
    if (html !== null) {
      html.setAttribute('dir', 'rtl');
      changeRtl(true);
    }
  };

  const onLtrChange = () => {
    const html = document.querySelector('html');
    if (html !== null) {
      html.setAttribute('dir', 'ltr');
      changeRtl(false);
    }
  };


  const modeChangeTopNav = () => {
    changeMenuMode(true);
  };

  const modeChangeSideNav = () => {
    changeMenuMode(false);
  };

  const onEventChange = {
    onRtlChange,
    onLtrChange,
    modeChangeTopNav,
    modeChangeSideNav,
  };

  return (
      <div style={{ position: "relative"}}>
        <SimpleBar style={{  maxHeight: '100vh',  }}>
          <Menu style={{ paddingTop: 115, minHeight: '100vh' }}>
            <MenuItems
              topMenu={topMenu}
              toggleCollapsed={toggleCollapsedMobile}
              events={onEventChange}
            />
          </Menu>
        </SimpleBar>
      </div>

  );
}

export default MainSidebar;
