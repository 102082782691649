import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";
import React from "react";

const Social = () => {
    return (
        <div>
            <MainNavbar head={"Overview"} text={"Detailed information about your website"} />
            <div style={{ background: "#FFF", margin: '10px 36px', padding: '30px'  }}>
                This is the social page
            </div>
        </div>
    )
}

export default Social;