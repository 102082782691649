import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import './HeaderItemSelect.scss'

interface HeaderItemProps {
    activeItem: string;
}

const HeaderItemSelect = ({activeItem}: HeaderItemProps) => {
    return(
        <div className="toptext">
            <div className={"toptext__group " + (activeItem.toLowerCase() === 'location'  ?  "toptext__group--active" : "")}>
                <span className="toptext__group--text">Location</span>
                <FontAwesomeIcon icon={faAngleDown} size={"xs"}/>
            </div>
            <div className={"toptext__group " + (activeItem.toLowerCase() === 'branding'  ?  "toptext__group--active" : "")}>
                <span className="toptext__group--text">Branding</span>
                <FontAwesomeIcon icon={faAngleDown} size={"xs"}/>
            </div>
            <div className={"toptext__group " + (activeItem.toLowerCase() === 'insights'  ?  "toptext__group--active" : "")}>
                <span className="toptext__group--text">Insights</span>
                <FontAwesomeIcon icon={faAngleDown} size={"xs"}/>
            </div>
            <div className={"toptext__group " + (activeItem.toLowerCase() === 'competitors'  ?  "toptext__group--active" : "")}>
                <span className="toptext__group--text">Competitors</span>
                <FontAwesomeIcon icon={faAngleDown} size={"xs"}/>
            </div>
        </div>
    )
}

export default HeaderItemSelect;