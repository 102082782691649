import React from "react";
import HeaderItemSelect from "../../../components/Base/HeaderitemSelect/HeaderItemSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import './setupKeywords.scss';
import MainNavbar from "../../../components/Layout/MainNavbar/MainNavbar";


const navbarHead = (
    <div className="topItem">
        <span className="topItem__text">Setup keywords and adjectives</span>
        <FontAwesomeIcon icon={faAngleDown} size={"xs"} />
    </div>
)


const setupKeywords = () => {

    return (
        <div>
        <MainNavbar   head={navbarHead} text={<HeaderItemSelect activeItem="insights" />}/>
            <div className="setup-keyword-page">
                <div className="top-bar">
                    <div className="top-bar__head">
                        Setup keywords and adjectives
                    </div>
                    <div className="top-bar__button">
                        <button className="btn btn-black">
                            Add a category
                        </button>
                    </div>
                </div>
                <div className="setup__group">
                    <div className="setup__group--item sgi">
                        <div className="sgi__head">
                            <div className="sgi__head--text">
                                Whitelisted keywords
                            </div>
                            <div className="sgi__head--actions">
                                <button className="btn text-btn">
                                    Add
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <div className="sgi__body">
                                <div className="sgi__body--no-text">
                                    You don’t have any whitelisted keyword
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="setup__group--item sgi">
                        <div className="sgi__head">
                            <div className="sgi__head--text">
                                Blacklisted Keywords
                            </div>
                            <div className="sgi__head--actions">
                                <button className="btn text-btn">
                                    Add
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <div className="sgi__body">
                                <div className="sgi__body--no-text">
                                    You don’t have any whitelisted keyword
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="setup__group--item sgi">
                        <div className="sgi__head">
                            <div className="sgi__head--text">
                                Whitelisted adjectives
                            </div>
                            <div className="sgi__head--actions">
                                <button className="btn text-btn">
                                    Add
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <div className="sgi__body">
                                <div className="sgi__body--no-text">
                                    You don’t have any whitelisted keyword
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="setup__group--item sgi">
                        <div className="sgi__head">
                            <div className="sgi__head--text">
                                Whitelisted adjectives
                            </div>
                            <div className="sgi__head--actions">
                                <button className="btn text-btn">
                                    Add
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <div className="sgi__body">
                                <div className="sgi__body--no-text">
                                    You don’t have any whitelisted keyword
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default setupKeywords;