import React from 'react'
import '../../review.scss';
import HeaderItemSelectRSNT from "../../../components/Base/HeaderitemSelectRSNT/HeaderItemSelectRSNT";
import FilterReview from "../../../components/Filters/FilterReview";
import {generateRandomInt} from "../../../utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './sentimentsByLocation.scss'
import MainNavbar from "../../../components/Layout/MainNavbar/MainNavbar";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const labels = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O",];

const initialData = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data:  labels.map(() => generateRandomInt(20, 45)),
            backgroundColor: "#C6261A",
            barThickness: 3,
        },
        {
            label: 'Dataset 2',
            backgroundColor: "#F4EB10",
            barThickness: 3,
        },
        {
            label: 'Dataset 3',
            data:  labels.map(() => generateRandomInt(30, 45)),
            backgroundColor: "#3D9C73",
            barThickness: 3,
        },
    ]
};
const options = {
        plugins: {
            title: {
                display: false,
                text: ''
            },
        },
        responsive: true,
        scales: {
            x: {
                display: false,
                stacked: true,
                barThickness: 10,
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 20,
                }
            },
        }
    };


const sentimentReport = () => {
    const datasetOneData = initialData.datasets[0]['data'];
    const datasetThreeData = initialData.datasets[2]['data'];

    // @ts-ignore
    const dataSetTwoData = datasetOneData.map((val, index)=> 100 - (datasetOneData[index] + datasetThreeData[index]))

    const finalData = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data:  datasetOneData,
                backgroundColor: "#C6261A",
            },
            {
                label: 'Dataset 2',
                data: dataSetTwoData,
                backgroundColor: "#F4EB10",
            },
            {
                label: 'Dataset 3',
                data:  datasetThreeData,
                backgroundColor: "#3D9C73",
            },
        ]
    };

  return (
    <div>
        <MainNavbar   head={"Review and Ratings over time"} text={<HeaderItemSelectRSNT activeItem={"sentiment"} />} />
        <div className="sentiment-by-location-page">
            <FilterReview />

            <div className="sbl">
                <div className="sbl__head">
                    Sentiment by location
                </div>

                <div className="sbl__chart">
                    <Bar
                        options={options}
                        data={finalData}
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

export default sentimentReport;