import React from "react";
import {Select,} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
     faCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import './FilterReview.scss'

const { Option } = Select;


const FilterReviewFull = () => {
    return (
        <div className="filter-review-component">
            <div className="filter">
                <div className="filter__head">
                    <div className="filter__type">
                        <div className="filter__type--item selected">
                            Basic
                        </div>
                        <div className="filter__type--item">
                            Advanced
                        </div>
                        <div className="filter__type--item">
                            Saved (15)
                        </div>
                    </div>
                    <div className="filter__clear">
                        <button className="btn text-btn clearform">
                            <span style={{ paddingRight: '5px' }}>clear</span>
                            <FontAwesomeIcon icon={faCircleXmark} size={"xs"} />
                        </button>
                    </div>
                </div>
                <div className="filter__items">
                    <div className="form">
                        <div className="form__group">
                            <label htmlFor="" className="form__label">City</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Selected</Option>
                                <Option value="others">others</Option>
                            </Select>
                        </div>
                        <div className="form__group">
                            <label htmlFor="" className="form__label">State</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Selected</Option>
                                <Option value="others">others</Option>
                            </Select>
                        </div>
                        <div className="form__group">
                            <label htmlFor="" className="form__label">CINTR</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Selected</Option>
                                <Option value="others">others</Option>
                            </Select>
                        </div>
                        <div className="form__group">
                            <label htmlFor="" className="form__label">Amazon</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Selected</Option>
                                <Option value="others">others</Option>
                            </Select>
                        </div>
                        <div className="form__group">
                            <label htmlFor="" className="form__label">Walmart</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Selected</Option>
                                <Option value="others">others</Option>
                            </Select>
                        </div>
                        <div className="form__group">
                            <label htmlFor="" className="form__label">Sephora</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Selected</Option>
                                <Option value="others">others</Option>
                            </Select>
                        </div>
                        <div className="form__group">
                            <label htmlFor="" className="form__label">Review Source</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="all">All Selected</Option>
                                <Option value="amazon">Amazon</Option>
                                <Option value="walmart">Walmart</Option>
                                <Option value="sephora">Sephora</Option>
                                <Option value="ulta">Ulta</Option>
                                <Option value="other">Other</Option>
                            </Select>
                        </div>
                        <div className="form__group">
                            <label htmlFor="" className="form__label">Time</label>
                            <Select defaultValue="all" className="form__select">
                                <Option value="30days">Past 30 Days</Option>
                                <Option value="14days">Past 14 days</Option>
                                <Option value="7days">Past 7 days</Option>
                                <Option value="3days">Past 3 days</Option>
                                <Option value="24hrs">past 24 hours</Option>
                            </Select>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterReviewFull;