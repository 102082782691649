import React from "react";
import './sidebar.scss'

const Sidebar = () => {

    return (
        <div className="sidebar">
            <div className="sidebar__menu">
                <div className="sidebar__menu--item">
                    Location
                </div>
                <div className="sidebar__menu--item">
                    Categories
                </div>
                <div className="sidebar__menu--item">
                    Topics
                </div>
                <div className="sidebar__menu--item">
                    Snippet
                </div>
            </div>
        </div>
    )
}

export default Sidebar;