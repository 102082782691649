import React  from "react";
import MainSidebar from "../components/Layout/MainSidebar/MainSidebar";
import { Layout, Col, Row } from 'antd';
import './Default.scss';


interface layoutProps {
  children?: React.ReactNode;
}


const DefaultLayout = ({ children }: layoutProps) => (
        <Layout>
        <Row>
          <Col xs={0} md={6} lg={4} style={{ minHeight: "100vh", height: "100%" }}>
            <div className="default__sidebar">
              <MainSidebar
                  topMenu={true}
                  changeRtl={()=>{}}
                  changeLayout={()=>{}}
                  changeMenuMode={()=>{}}
              />
            </div>
          </Col>
          <Col
            className=""
            xs={{ span: 24 }}
            md={{ span: 18 }}
            lg={{ span: 20 }}
          >
            <div className="default__children">
              {children}
            </div>
          </Col>
        </Row>
      </Layout>
);

export default DefaultLayout;