import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EmptyFound from '../../assets/images/empty_found.png'
import  './insights.scss'
import HeaderItemSelect from "../../components/Base/HeaderitemSelect/HeaderItemSelect";
import MainNavbar from "../../components/Layout/MainNavbar/MainNavbar";
const navbarHead = (
    <div className="topItem">
        <span className="topItem__text">Setup</span>
        <FontAwesomeIcon icon={faAngleDown} size={"xs"} />
    </div>
)

const Insights = () => {
    return (
        <div>
        <MainNavbar   head={navbarHead} text={<HeaderItemSelect activeItem="insights" />}/>
            <div className="insights">
                <div className="top-bar">
                    <div className="top-bar__head">
                        Insights
                    </div>
                    <div className="top-bar__button">
                        <button className="btn btn-black">
                            Add a category
                        </button>
                    </div>
                </div>
                <div className="empty-category">
                    <div className="empty-category__text">
                        You have not setup any categories yet
                    </div>
                    <div className="empty-category__image">
                        <img src={EmptyFound} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Insights;