import React, {useState} from "react";
import Sidebar from "../../components/KeywordsAndAdjectives/sidebar";
import {Select,} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircle,
    faSliders
} from "@fortawesome/free-solid-svg-icons";
import './keywordAndAdjectives.scss'
import LinearProgressBar from "../../components/Base/LinearProgressBar";

const { Option } = Select;

const data = [
    {
        name: "Atmosphere",
        score: 50
    },
    {
        name: "Birthday",
        score: 34
    },
    {
        name: "Blood Orange Lemonade",
        score: 57
    },
    {
        name: "Bread",
        score: 45
    },
    {
        name: "Chicken",
        score: 23
    },
    {
        name: "Crust",
        score: 54
    },
    {
        name: "Dinner",
        score: 67
    },
    {
        name: "Entertainment",
        score: 86
    },
    {
        name: "Everything",
        score: 135
    },
    {
        name: "Experience",
        score: 236
    },
    {
        name: "Family",
        score: 15
    },
]

const KeywordsAndAdjectives = () => {
    const [showFilter, setShowFilter] = useState(true);

    const toggleShowFilter = () => {
        setShowFilter(!showFilter);
    }

    return (
        <div className="keywords-and-adjectives kaa">
            <div className="kaa">
                <div className="kaa__sidebar">
                    <Sidebar />
                </div>
                <div className="kaa__main">
                    <div className="kaa__head">
                        Keywords and Adjectives
                    </div>
                    <div className="kaa__post-head">
                        <div className="kaa__post-head--nav">
                            <div className="kaa__post-head--nav-item selected">
                                Basic
                            </div>
                            <div className="kaa__post-head--nav-item">
                                Advanced
                            </div>
                            <div className="kaa__post-head--nav-item">
                                Saved (13)
                            </div>
                        </div>

                        <div className="kaa__post-head--filter">
                            <div className="form__group">
                                <Select defaultValue="time" className="form__select">
                                    <Option value="time">Time</Option>
                                    <Option value="others">others</Option>
                                </Select>
                            </div>
                            <FontAwesomeIcon icon={faSliders} size={"lg"} />
                        </div>
                    </div>

                    <div className="kaa__item">
                        <div className="kaa__keywords">
                            <div className="keyword">
                                <div className="keyword__groups">
                                    <div className="keyword__group">
                                        { data && data.length > 0 ?
                                            data.map ((dataItem, index)=>(
                                                <div className="keyword__group--item" key={index}>
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        size={"lg"}
                                                        style={{ marginRight: "20px", color: dataItem.score !== 135 && dataItem.score > 50 ? "#22282F" : dataItem.score !== 135 ? "#E9E9E9" : "#FD392B"  }}
                                                    />
                                                    {` ${dataItem.name}  (${dataItem.score}) `}
                                                </div>
                                            ))
                                            : <></>
                                        }

                                    </div>
                                    <div className="keyword__group">
                                        { data && data.length > 0 ?
                                            data.map ((dataItem, index)=>(
                                                <div className="keyword__group--item" key={index}>
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        size={"lg"}
                                                        style={{ marginRight: "20px", color: dataItem.score !== 135 && dataItem.score > 50 ? "#22282F" : dataItem.score !== 135 ? "#E9E9E9" : "#FD392B"  }}
                                                    />
                                                    {` ${dataItem.name}  (${dataItem.score}) `}
                                                </div>
                                            ))
                                            : <></>
                                        }

                                    </div>
                                </div>
                                <button className="btn btn-black">
                                    See All
                                </button>
                            </div>
                            <div className="analysis">
                                <div className="analysis__head">
                                    Sentiments B, 43 mentions
                                </div>
                                <div className="analysis__text">
                                    Adjectives often related to experience
                                </div>
                                <div className="analysis__item--group">
                                    <div className="analysis__item">
                                        <div className="analysis__item--text">
                                            Great
                                        </div>
                                        <div className="analysis__item--rating">
                                            <LinearProgressBar label={""} score={80} total={100} />
                                        </div>
                                    </div>
                                    <div className="analysis__item">
                                        <div className="analysis__item--text">
                                            Good
                                        </div>
                                        <div className="analysis__item--rating">
                                            <LinearProgressBar label={""} score={40} total={100} />
                                        </div>
                                    </div>
                                    <div className="analysis__item">
                                        <div className="analysis__item--text">
                                            Crunchy
                                        </div>
                                        <div className="analysis__item--rating">
                                            <LinearProgressBar label={""} score={30} total={100} />
                                        </div>
                                    </div>
                                    <div className="analysis__item">
                                        <div className="analysis__item--text">
                                            First
                                        </div>
                                        <div className="analysis__item--rating">
                                            <LinearProgressBar label={""} score={0} total={100} />
                                        </div>
                                    </div>
                                    <div className="analysis__item">
                                        <div className="analysis__item--text">
                                            Great
                                        </div>
                                        <div className="analysis__item--rating">
                                            <LinearProgressBar label={""} score={15} total={100} />
                                        </div>
                                    </div>

                                </div>
                                <div className="analysis__btn">
                                    <button className="btn text-btn">
                                        See more
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            showFilter ?
                                <div className="filter">
                                    <div className="form">
                                        <div className="form__groups">
                                            <div className="form__group">
                                                <div className="form__label">State</div>
                                                <Select defaultValue="all" className="form__select">
                                                    <Option value="all">All Selected</Option>
                                                    <Option value="others">others</Option>
                                                </Select>
                                                <Select defaultValue="all" className="form__select">
                                                    <Option value="all">All Keyword</Option>
                                                    <Option value="others">others</Option>
                                                </Select>
                                            </div>
                                            <div className="form__group">
                                                <div className="form__label">City</div>
                                                <Select defaultValue="all" className="form__select">
                                                    <Option value="all">All Selected</Option>
                                                    <Option value="others">others</Option>
                                                </Select>
                                                <Select defaultValue="all" className="form__select">
                                                    <Option value="all">Sort of Occurrences</Option>
                                                    <Option value="others">others</Option>
                                                </Select>
                                            </div>

                                            <div className="form__group">
                                                <div className="form__label">Location</div>
                                                <Select defaultValue="all" className="form__select">
                                                    <Option value="all">All Selected</Option>
                                                    <Option value="others">others</Option>
                                                </Select>
                                                <Select defaultValue="all" className="form__select">
                                                    <Option value="all">Show top 25</Option>
                                                    <Option value="others">others</Option>
                                                </Select>
                                            </div>
                                            <div className="form__group">
                                                <div className="form__label">Review Source</div>
                                                <Select defaultValue="all" className="form__select">
                                                    <Option value="all">All Selected</Option>
                                                    <Option value="others">others</Option>
                                                </Select>
                                                <Select defaultValue="all" className="form__select">
                                                    <Option value="all">Show all sentiment</Option>
                                                    <Option value="others">others</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="form__button">
                                            <button className="btn btn-black" onClick={toggleShowFilter}>
                                                Apply Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                : <></>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default KeywordsAndAdjectives;